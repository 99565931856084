<template>
  <div style="margin:15px;">
    <h3>手机号登录</h3>
    <p style="margin:7px 0;">
      <small>未注册手机号会自动注册</small>
    </p>
  </div>
  <!-- oO-6lw_YRjMJg-dSaItAW6fFwDxk -->
  <van-form @submit="onSubmit" style="">
    <van-cell-group title="" inset>
      <van-field
        v-model="phone"
        name="phone"
        label="手机号"
        placeholder="请输入"
      />
      <van-field
        v-model="captcha"
        name="captcha"
        center
        clearable
        label="验证码"
        placeholder="请输入"
      >
        <template #button>
          <van-button size="small" type="primary" @click="onSendCaptcha">发送验证码</van-button>
        </template>
      </van-field>
    </van-cell-group>
    <div style="margin: 16px;">
      <van-button round block type="primary" native-type="submit">确认登录</van-button>
    </div>
  </van-form>
  <rights />
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'
import Rights from '@/components/Rights.vue'
import { Toast, Dialog } from 'vant'
import req from '@/util/request-new'
// import { login } from '@/util/biz'

export default {
  components: {
    Rights
  },
  setup () {
    const Cookies = inject('Cookies')
    const useRouter = inject('useRouter')
    const useRoute = inject('useRoute')
    const state = reactive({
      show: false,
      phone: '',
      captcha: '',
      initOnLogout: useRoute.query.initOnLogout ?? false,
      redirect: useRoute.query.redirect ?? '',
      role: useRoute.query.role ?? '',
      token: Cookies.get('token') ?? '',
      wechatOpenid: Cookies.get('wechatOpenid') ?? ''
    })
    const vantDialog = (obj, callback) => {
      Dialog.confirm(obj).then(() => {
        callback()
      })
    }
    const onClearCache = () => {
      const obj = Cookies.get()
      for (const item of Object.keys(obj)) {
        Cookies.remove(item)
      }
      Dialog.alert({
        title: '提示',
        message: '缓存已清理，请关闭页面后重新进入'
      }).then(() => {
      })
    }
    const onSendCaptcha = () => {
      req.post('/util.sendCaptcha', {
        phone: state.phone
      }).then(res => {
        if (res.code === 0) {
          Toast.success('短信已发送')
        } else {
          Toast(res.msg)
        }
      })
    }
    const onSubmit = (values) => {
      Toast.loading({ forbidClick: true, duration: 0 })
      req.post('/user.login', {
        phone: state.phone,
        captcha: state.captcha,
        wechatOpenid: state.wechatOpenid,
        role: state.role,
        type: 'CAPTCHA'
      }).then(res => {
        Toast.clear()
        if (res.code === 0) {
          loginSuccess(res.data)
        } else {
          if (res.code === 11) {
            vantDialog({
              title: '当前微信已绑定其他手机号',
              message: '当前微信已与手机号：' + res.msg + '绑定',
              confirmButtonText: '换绑'
            }, () => {
              useRouter.push({
                name: 'AccountWechatSettings',
                query: {
                  oldPhone: res.msg
                }
              })
            })
          } else {
            Toast(res.msg)
          }
        }
      })
    }
    const getMmeber = (userId) => {
      return req.post('/member.get', {
        userId: userId,
        storeId: Cookies.get('storeId')
      })
    }
    const getStaff = (userId) => {
      return req.post('/staff.get', {
        userId: userId,
        storeId: Cookies.get('storeId')
      })
    }
    const loginSuccess = ({ token, userId, user, roleId }) => {
      Toast.success()
      Cookies.set('userId', userId, { expires: 1 })
      Cookies.set('token', token, { expires: 1 })
      if (state.role === 'BUYER') {
        Cookies.set('buyerId', roleId, { expires: 1 })
        req.all([getMmeber(userId), getStaff(userId)]).then(req.spread((resMember, resStaff) => {
          if (resMember.code === 0) {
            Cookies.set('memberId', resMember.data.memberId, { expires: 1 })
          }
          if (resStaff.code === 0) {
            Cookies.set('staffId', resStaff.data.staffId, { expires: 1 })
            Cookies.set('staffType', resStaff.data.type, { expires: 1 })
            Cookies.set('staffSale', resStaff.data.sale, { expires: 1 })
          }
          useRouter.replace(state.redirect)
        }))
        // post('/member.get', {
        //   userId: userId,
        //   storeId: Cookies.get('storeId')
        // }).then(res => {
        //   if (res.code === 0) {
        //     Cookies.set('memberId', res.data.memberId, { expires: 1 })
        //   }
        // })
        // post('/staff.get', {
        //   userId: userId,
        //   storeId: Cookies.get('storeId')
        // }).then(res => {
        //   if (res.code === 0) {
        //     Cookies.set('staffId', res.data.staffId, { expires: 1 })
        //   }
        // })
        // if (state.wechatOpenid !== '') {
        //   req.post('/user.bindWechat', {
        //     userId: userId,
        //     wechatOpenid: state.wechatOpenid,
        //     bind: true
        //   }).then(res => {
        //   })
        // }
        if (!user.wechatOpenid) {
          req.post('/user.bindWechat', {
            userId: userId,
            wechatOpenid: state.wechatOpenid,
            bind: true
          }).then(res => {
          })
        }
        req.post('/buyer.get', {
          buyerId: roleId
        }).then(res => {
          if (res.data.avatar === '') {
            req.post('/buyer.update', {
              buyerId: roleId,
              name: Cookies.get('wechatNickname'),
              avatar: Cookies.get('wechatHeadimgurl')
            }).then(res => {
            })
          }
        })
      }
      if (state.role === 'SELLER') {
        Cookies.set('sellerId', roleId, { expires: 1 })
        useRouter.replace(state.redirect)
      }
    }
    const init = () => {
      if (state.token !== '') {
        useRouter.replace(state.redirect)
      }
      if (state.role !== 'BUYER' && state.role !== 'SELLER') {
        Toast('不支持的用户类型')
      }
      if (!state.initOnLogout) {
        if (state.wechatOpenid !== '') {
          Toast.loading({ forbidClick: true, duration: 0 })
          req.post('/user.login', {
            wechatOpenid: state.wechatOpenid,
            role: state.role,
            type: 'WECHAT'
          }).then(res => {
            Toast.clear()
            if (res.code === 0) {
              loginSuccess(res.data)
            }
          })
        }
      }
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      onSubmit,
      onSendCaptcha,
      onClearCache
    }
  }
}
</script>
